import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAPI, putAPI } from "../../apis/apis";
import FileHeartIcon from "../../assets/icons/fileHeartIcon";
import List from "../../assets/icons/list";
import SearchReflation from "../../assets/icons/searchReflation";
import { connect } from "socket.io-client";
import Button from "../../atoms/Button";
import Loader from "../../atoms/Loader";
import { useDbounce } from "../../hooks/debounce.hook";
import CustomTable from "../../molecules/table/CustomTable";
import {
  formartNumberAsLocalString,
  timeAgoFormatter,
} from "../../utils/utils";
import { setListIsLoading, setMyLists } from "./reducer";
import { fetchMyList } from "./sagaActions";
import { getListIsLoading, getMyListTotal, getMylistData } from "./selectors";
import { fetchlistExportCSVList } from "./listService";
import { ListRowOptions } from "./ListRowOptions";
import DotVertical from "../../assets/icons/dotsVertical";
import { columns } from "./constants";
import FilterDropdown from "../filterDropdown/FilterDropdown";
import { ListsTableProps } from "./interface";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { getProfileInfo } from "../../components/profileMenu/selectors";

const ListsTable = ({
  filter,
  handleClearSearch,
  handleNextPage,
  toggleImportFileModal,
  handleSorting,
}: ListsTableProps) => {
  const dispatch = useDispatch();
  const CurrentId = useRef();
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [listNameDisable, setlistNameDisable] = useState(false);
  const [leadDetail, setLeadDetail] = useState({});
  const [showlistOptions, setShowListOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const myLists = useSelector((state) => getMylistData(state));
  const listLoading = useSelector((state) => getListIsLoading(state));
  const total = useSelector((state) => getMyListTotal(state));  
  const[ updateList, setUpdateList]=useState<Record<string, any>[]>([...myLists]);
  const socketRef = useRef<any>();
  const profileData = useSelector((state) => getProfileInfo(state));

  const debounce = useDbounce(500, (data: any) => {
    dispatch(
      fetchMyList({
        search: data.searchText,
        index: data.pageNumber,
        size: data.limit,
        sortDirection: data.sortDirection,
        sortKey: data.sortKey,
      })
    );
  });

  const handleCSVData = async (data: any) => {
    CurrentId.current = data;
    setLoading(true);
    try {
      const response = await fetchlistExportCSVList(data);
      const listingData = response.data?.data?.map((list: any) => {
        return {
          nameFirst: list?.owner[0]?.nameFirst ?? "",
          nameLast: list?.owner[0]?.nameLast ?? "",
          addressStreet: list?.addressStreet ?? "",
          addressCity: list?.addressCity ?? "",
          addressState: list?.addressState ?? "",
          addressZip: list?.addressZip ?? "",
          phone1: list?.phones[0]?.number ?? "",
          phone2: list?.phones[1]?.number ?? "",
          phone3: list?.phones[2]?.number ?? "",
        };
      });
      return listingData;
    } catch (err) {
     console.log(err)
    } finally {
      setLoading(false);
    }
  };

  const handleSkipData = async (data: any) => {
    CurrentId.current = data;
    setLoading(true);
    try {
      const response = await fetchlistExportCSVList(data);
      const listingData = response?.data?.data?.map((list: any) => {
        return {
          Owner1FirstName:`"${ list.searchData?.jsonData?.owner1FirstName ?? "" }"`,
          Owner1LastName:
           `"${ list.searchData?.jsonData?.owner1LastName ?? ""}"`,
          Owner2FirstName:
           `"${ list.searchData?.jsonData?.owner2FirstName ??
            "" }"`,
          Owner2LastName:
           `"${ list.searchData?.jsonData?.owner2LastName ?? ""}"`,
          MailingStreetAddress:
           `"${ list?.searchData?.jsonData?.mailAddress?.street ?? ""}"`,
          MailingCity: `"${list?.searchData?.jsonData?.mailAddress?.city ?? ""}"`,
          MailingState: `"${list?.searchData?.jsonData?.mailAddress?.state ?? ""}"`,
          MailingZip:`"${ list?.searchData?.jsonData?.mailAddress?.zip ?? ""}"`,
          MailingCounty: `"${list?.searchData?.jsonData?.mailAddress?.county ?? ""}"`,
          PropertyStreetAddress:
           `"${list?.searchData?.jsonData?.address?.street ?? ""}"`,
          PropertyCity: `"${list?.searchData?.jsonData?.address?.city ?? "" }"`,
          PropertyState: `"${list?.searchData?.jsonData?.address?.state ?? ""}"`,
          PropertyZip:`"${ list?.searchData?.jsonData?.address?.zip ?? ""}"`,
          PropertyCounty: `"${list?.searchData?.jsonData?.address?.county ?? ""}"`,
          Email1:
            list.skipData?.jsonData?.emails?.[0]?.email ?? "",
          Email2:
            list.skipData?.jsonData?.emails?.[1]?.email ?? "",
          Phone1:
            list?.skipData?.jsonData?.phoneNumbers?.[0]?.number ?? "",
          Phone2:
            list?.skipData?.jsonData?.phoneNumbers?.[1]?.number?? "",
          Phone3:
            list.skipData?.jsonData?.phoneNumbers?.[2]?.number ?? "",
          Phone4:
            list.skipData?.jsonData?.phoneNumbers?.[3]?.number ?? "",
          Phone5:
            list.skipData?.jsonData?.phoneNumbers?.[4]?.number ?? "",
          Phone6:
            list.skipData?.jsonData?.phoneNumbers?.[5]?.number ?? "",
          IsVacant: `"${list?.searchData?.jsonData?.vacant ?? ""}"`,
          IsAbsentee: `"${list?.searchData?.jsonData?.absenteeOwner ?? ""}"`,
          APN: `"${list?.searchData?.jsonData?.apn ?? ""}"`,
          PropertyType:`"${list?.searchData?.jsonData?.propertyType ?? "" }"`, 
          PropertyUse: `"${list?.searchData?.jsonData?.propertyUse ?? ""}"`,
          BedroomCount: `"${list?.searchData?.jsonData?.bedrooms ?? ""}"`,
          BathroomCount: `"${list?.searchData?.jsonData?.bathrooms ?? ""}"`,
          BuildingSquareFeet: `"${list?.searchData?.jsonData?.squareFeet ?? ""}"`,
          LotSquareFeet: `"${list?.searchData?.jsonData?.lotSquareFeet ?? ""}"`,
          YearBuilt:`"${ list?.searchData?.jsonData?.yearBuilt ?? ""}"`,
          AssessedValue: `"${list?.searchData?.jsonData?.assessedValue ?? ""}"`,
          // AssessedLandValue:
          //   list?.searchData?.jsonData?.assessedLandValue ?? "",
          // AssessedImprovementValue:
          //   list?.searchData?.jsonData?.assessedImprovementValue ?? "",
          LastSaleDate: `"${list?.searchData?.jsonData?.lastSaleDate ?? ""}"`,
          LastSalePrice: `"${list?.searchData?.jsonData?.lastSaleAmount ?? ""}"`,
          MortgageBalanceOpen:
            `"${list?.searchData?.jsonData?.openMortgageBalance ?? ""}"`,
          MortgageLenderName: `"${list?.searchData?.jsonData?.lenderName ?? ""}"`,
          MortgageType: `"${list?.searchData?.jsonData?.lenderName ?? ""}"`,
          EstimatedEquity: `"${list?.searchData?.jsonData?.estimatedEquity ?? ""}"`,
          EstimatedValue: `"${list?.searchData?.jsonData?.estimatedValue ?? ""}"`,
          MLSStatus: `"${list?.searchData?.jsonData?.mlsActive ?? ""}"`,
          MLSListingDate: `"${list?.searchData?.jsonData?.mlsListingDate ?? ""}"`,
          MLSListingPrice: `"${list?.searchData?.jsonData?.mlsListingPrice ?? ""}"`,
          // MLSListingAgentFullName:
          //   list?.searchData?.jsonData?.listhub?.ListAgentFullName ?? "",
          // MLSListingAgentPhone:
          //   list?.searchData?.jsonData?.listhub?.ListAgentPreferredPhone ?? "",
          // MLSListingAgentEmail:
          //   list?.searchData?.jsonData?.listhub?.ListAgentEmail ?? "",
          // MLSListingBrokerageName:
          //   list?.searchData?.jsonData?.listhub?.ListingKey ?? "",
          // MLSListingBrokeragePhone:
          //   list?.searchData?.jsonData?.listhub?.ListAgentOfficePhone ?? "",
            AISummary: `"${list?.aiSummary ?? ""}"`
        };
      });
      setLoading(false);
      return listingData;
    } catch (err) {
      console.log(err)
      setLoading(false);
    } 
  };

  const handleDownload = async (type: string, id: any) => {
    if (type === "uploaded") {
      return await handleCSVData(id);
    } else {
      return await handleSkipData(id);
    }
  };

  const editname = (id: number) => {
    const getIndex = tableData?.findIndex((el) => {
      return el.id === id;
    });
    setListIndex((prev) => {
      if (prev !== -1 && (prev === 0 || prev)) {
        tableData[prev].list.notEdittable = true;
        if(tableData[prev].list.title === ''){
          tableData[prev].list.title = updateList[prev]?.name
        }
        tableData[prev].list.title = updateList[prev]?.name;  
      }
      tableData[getIndex].list.notEdittable = false;
      return getIndex;
    });
    setTableData([...tableData]);
  };

  const handleListChange = (e: any) => {
    tableData[listIndex].list.title = e.target.value;
    setTableData([...tableData]);
  };

  const handleSave = () => {
    if(tableData[listIndex]?.list.title.length >= 100){
      handleShowToast("List name must be atmost 100 characters.", ToastType.error);
    }else{
    renameList(tableData[listIndex]?.id, tableData[listIndex]?.list.title.trim());
    setTableData([...tableData]);
    }
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleSaveKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !listNameDisable && listIndex != -1) {
      e.preventDefault();
      handleSave();
    }
  };
  
  const handleAttemptClose = () => {
    setAnchorOptions(null);
    setShowListOptions(false);
  };


  const deleteList = useCallback(
    async (leadId: number) => {
      try {
        setDeleteLoading(true);
        await deleteAPI({
          url: `${process.env.REACT_APP_BASE_URL}/list/${leadId}`,
          headers: {},
        });
        const updatedList = tableData.filter((el: any) => el.id !== leadId);
        setTableData(updatedList);
        const updatedMylist= myLists.filter((el: any) => el.id !== leadId);
       dispatch( setMyLists({data:updatedMylist,total:updatedMylist.length}))
        setShowListOptions(false);
        setDeleteLoading(false);
        handleShowToast("List deleted successfully!", ToastType.success);
      } catch (error: unknown) {
        console.error(error);
        setDeleteLoading(false);
      }
    },
    [tableData]
  );

  const renameList = useCallback(
    async (listId: number, listName: string) => {
      try {
        setlistNameDisable(true);
        await putAPI({
          url: `${process.env.REACT_APP_BASE_URL}/list/${listId}`,
          headers: {},
          data: {
            listName: listName,
          },
        });
        tableData[listIndex].list.title = listName;
        tableData[listIndex].list.notEdittable = true;
        
        setUpdateList(prevList => prevList.map((item, index) => index === listIndex ? { ...item, name: listName } : item));
        setTableData([...tableData]);
        setShowListOptions(false);
        setlistNameDisable(false);
        setListIndex(-1);
        handleShowToast(`${listName} list name is updated successfully! `, ToastType.success);
      } catch (error: unknown) {
        console.error(error);
        handleShowToast("List name is required", ToastType.error);
        setlistNameDisable(false);
        setUpdateList([...myLists])
      }
    },
    [tableData]
  );

  useEffect(() => {
    if (
      filter.searchText.length > 0 &&
      filter.searchText.length !== filter.searchText.trim().length
    ) {
      return;
    }
    if (filter.pageNumber === 1) {
      dispatch(setListIsLoading(true));
    }
    debounce(filter);
  }, [filter]);

  useEffect(() => {
    let list = myLists?.map((lead: any) => {
      return {
        id: lead?.id,
        list: {
          title: lead?.name ?? "",
          description: lead?.status !== "failed" ? (lead?.type === "ottolead" ? "Purchased" : (lead?.type === "uploaded" ? "Uploaded" : lead?.type)) : "Failed",
          notEdittable: true,
          tooltipRequired: true,
          status:lead?.status
        },
        uploadDate: timeAgoFormatter(new Date(lead?.createdAt)),
        records:
          lead?.status !== "uploading"
            ? formartNumberAsLocalString(lead?.recordCount ?? 0)
            : "-",
        phone:
          lead?.status !== "uploading"
            ? formartNumberAsLocalString(lead?.phonesCount ?? 0)
            : "-",
        downloadList: (
          <Button
            className="icon"
            prefix={<DotVertical />}
            action={(event: any) => {
              setShowListOptions(true);
              setLeadDetail(lead);
              setAnchorOptions(event.target);
            }}
          />
        ),
      };
    });
    setTableData(list ?? []);
  }, [myLists, loading]);

  useEffect(() => {
    socketRef.current = (connect as any)(
      `${process.env.REACT_APP_WS_BASE_URL}/notifications`,
      {
        extraHeaders: {
          "x-user-id": profileData?.id,
        },
      }
    );
   
    socketRef.current.on("list-status", (data: any) => {
      let newTableData: any = myLists?.map((el: any) => {
        if (el.id == data?.listId) {
          return { ...el, status: data?.listStatus,recordCount:data?.recordCount,phonesCount:data?.phonesCount};
        }
        return el;
      });
      dispatch(
        setMyLists({ ...myLists, data: newTableData, count: total  })
      );
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [myLists]);

  return (
    <>
      {listLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className="list-table">
          <CustomTable
            rows={tableData}
            columns={columns}
            total={total}
            currentPageIndex={1}
            search={filter.searchText}
            sortKey={filter.sortKey}
            sortDirection={filter.sortDirection}
            tableStriped
            hasMore={tableData.length < total}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
              description:
                "We couldn’t find any results that match your search or filtering criteria.",
              button1: (
                <Button
                  label="Import List"
                  className="primary animated"
                  prefix={<FileHeartIcon />}
                  action={() => toggleImportFileModal(true)}
                />
              ),
              button2: (
                <Button
                  label="Clear search"
                  className="outline"
                  action={handleClearSearch}
                />
              ),
            }}
            emptyTableMetaData={{
              icon: <List />,
              title: "Import List",
              description: "Click the button below to get started.",
              button1: (
                <Button
                  label="Import List"
                  className="primary animated"
                  prefix={<FileHeartIcon />}
                  action={() => toggleImportFileModal(true)}
                />
              ),
            }}
            handleNextPage={handleNextPage}
            handleSorting={handleSorting}
            handleListChange={handleListChange}
            editname={editname}
            handleSave={handleSave}
            listNameDisable={listNameDisable}
            handleSaveKeyPress={handleSaveKeyPress}
          ></CustomTable>
          {showlistOptions && (
            <FilterDropdown
              sizeList
              handleClose={handleAttemptClose}
              anchorEl={anchorOptions}
            >
              <ListRowOptions
                leadDetail={leadDetail}
                loading={loading}
                CurrentId={CurrentId}
                deleteList={deleteList}
                deleteLoading={deleteLoading}
                handleDownload={handleDownload}
              ></ListRowOptions>
            </FilterDropdown>
          )}
        </div>
      )}
    </>
  );
};
export default ListsTable;
